@import '@core/scss/core.scss';
@import './assets/scss/styles';

//classe per centrare header ag-grid
.centerHeader .ag-header-cell-label {
  justify-content: center !important;
}

//classe per aggiungere un colore custom ag-grid
.ag-row.isDetailRow {
  background-color: #dcdcdc; /* Mantieni il colore della riga dettaglio */
}
.ag-row.isDetailRow.ag-row-hover::before {
  content: none;
}
