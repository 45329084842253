//Ag grid
@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-material.css";

.ag-cell {
  padding: 4px 0;
}

.ag-row .ag-cell {
  display: flex;
  align-items: center;
}

.ag-theme-material .ag-cell, .ag-theme-material .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  line-height: 20px;
}

.ag-cell-wrapper {
  width: 100%;
}

.ag-theme-material .ag-row {
  font-size: 12px !important;
}

.ag-theme-material .ag-cell, .ag-theme-material .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  padding-left: 8px;
  padding-right: 8px;
}

.ag-theme-material .ag-header-cell, .ag-theme-material .ag-header-group-cell {
  padding-left: 10px;
  padding-right: 10px;
}
